import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fill-height justify-start pa-0",
      attrs: { id: "register" },
    },
    [
      _c("div", { staticClass: "dxa-form-page-div" }, [
        _c("div", { staticClass: "outside-information-div" }, [
          _c("div", [
            _c("p", { staticClass: "dxa-outside-header" }, [
              _vm._v(" " + _vm._s(_vm.$t("banker_onboarding_header")) + " "),
            ]),
            _c("p", { staticClass: "dxa-outside-subheader" }, [
              _vm._v(" " + _vm._s(_vm.$t("banker_onboarding_subheader")) + " "),
            ]),
          ]),
        ]),
      ]),
      !_vm.gs.isMobile()
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "upper-card-div" },
                [
                  _c(
                    VRow,
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        VCol,
                        {
                          staticClass: "pl-3",
                          staticStyle: { "border-radius": "12px" },
                          attrs: { cols: "12", md: "8" },
                        },
                        [
                          _c("h3", { staticClass: "dxa-upper-card-header" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("additional_information")) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VSlideYTransition,
                { attrs: { appear: "" } },
                [
                  _c(
                    VCard,
                    {
                      staticClass: "dxa-card",
                      attrs: { light: "", color: "white" },
                    },
                    [
                      _c(
                        VRow,
                        {
                          staticClass: "dxa-card-row",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            VCol,
                            {
                              staticClass: "dxa-card-col-8",
                              attrs: { cols: "12", md: "8" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "pa-2 pt-0",
                                  class: "text-left",
                                  staticStyle: { height: "100%" },
                                },
                                [
                                  _c("OnboardingForm", {
                                    attrs: { User: _vm.user },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            VCol,
                            {
                              staticClass: "dxa-card-col-4",
                              attrs: { cols: "0", md: "4" },
                            },
                            [
                              _c("img", {
                                staticClass: "dxa-upper-img",
                                attrs: {
                                  alt: "DXA",
                                  src: require("../../../../../assets/dxa-x-blue.svg"),
                                },
                              }),
                              _c("img", {
                                staticClass: "dxa-lower-img",
                                attrs: {
                                  alt: "Icons",
                                  src: require("../../../../../assets/dxa-stars-background.png"),
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "px-4" },
            [_c("OnboardingForm", { attrs: { User: _vm.user } })],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }